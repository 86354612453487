import React from "react";
import styles from "./TitleContainer.module.css";

export default function TitleContainer({title, Icon, description}) {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.titleWrapper}>
                    <div className={styles.iconWrapper}>
                        <Icon />
                    </div>
                    <h1 className={styles.title}>{title}</h1>
                </div>
                {description && (
                    <p className={styles.description}>{description}</p>
                )}
            </div>
        </>
    );
}
