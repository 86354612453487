import React from "react";

export default function BlogCard({blog}) {
    const {category, readTime, title, description, image} = blog;
    const rawDescription = `<p>${description.length > 256 ? `${description.substring(0, 256)}... <a href='/blog/${blog.id}' style="text-decoration: underline;color: #bf2e1b;">read more</a>` : description}</p>`;

    return (
        <div className="md:w-[420px] h-full flex flex-col gap-2 ">
            <div className="text-grayCustom text-[12px]">
                {category} | {readTime}
            </div>

            <div className="w-full h-[223px] relative bg-black bg-opacity-50">
                <div className="absolute inset-0 bg-black bg-opacity-40 rounded-xl"></div>
                <img
                    className="w-full h-full rounded-xl"
                    src={image}
                    alt={title}
                />
                <div className="absolute bottom-[10px] left-[21px] text-white font-bold text-[18px]">
                    {title}
                </div>
            </div>
            <div className="border-l-2 border-primary pl-[10px]">
                <div dangerouslySetInnerHTML={{__html: rawDescription}}></div>
            </div>
        </div>
    );
}
