import React from "react";
import ServiceLayout from "../../components/ServiceLayout/ServiceLayout";
import ContactBanner from "../../components/ContactBanner/ContactBanner";
import MicrosoftCalendarButton from "../../components/MicrosoftCalendarButton/MicrosoftCalendarButton";

export default function EmailFulfillmentPage() {
    return (
        <ServiceLayout
            leftPolygonContent={
                <div className="w-full px-[30px] py-[31px] !mt-5 sm:!mt-0">
                    <div className="flex gap-2 bg-primary align-center">
                        <img src="/icon2.png" alt="icon" />
                        <h1 className="font-bold md:text-[32px] text-[26px] my-auto">
                            Email fulfillment
                        </h1>
                    </div>
                    <h2 className="font-normal text-[15px]">
                        Do email marketing the right way.
                    </h2>

                    <div className="pl-[10px] border-l border-white mt-[29px] mb-[70px] flex gap-3 flex-col">
                        <p className="text-[16px]">
                            Our email campaign management and transmission
                            services provide you with the service and support
                            you need, helping you leverage your database, target
                            and deliver your message, and track results… easily
                            and with the utmost security available. You won’t
                            have to worry about deployment or the logistics
                            involved as our experienced email campaign managers
                            will guide and consult with you throughout the
                            entire process.
                        </p>
                    </div>
                </div>
            }
            rightPolygonContent={
                <>
                    <img
                        className="w-full mb-5 h-[350px] object-cover rounded-b-2xl hidden md:block"
                        src="/email-fulfillment.png"
                        alt={"banner"}
                    />

                    {/* <h2 className="text-2xl font-bold text-red-600 mb-6">
                        Our professional email services include:
                    </h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        <div>
                            <h3 className="text-lg font-semibold text-gray-800 mb-4">
                                Delivery
                            </h3>

                            <ul className="space-y-2 text-gray-600 list-disc list-inside ">
                                <li>Bulk Email Blasts</li>
                                <li>HTML or Text Email Set-up</li>
                                <li>Image Hosting</li>
                                <li>Suppression</li>
                                <li>Personalization of Transmission</li>
                                <li>Scheduling</li>
                            </ul>
                        </div>

                        <div>
                            <h3 className="text-lg font-semibold text-gray-800 mb-4">
                                Security
                            </h3>
                            <ul className="space-y-2 text-gray-600 list-disc list-inside">
                                <li>Email Manager Software</li>
                                <li>Spam Checks</li>
                                <li>CAN-SPAM Compliant</li>
                                <li>Blacklist Screening</li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold text-gray-800 mb-4">
                                Tracking
                            </h3>
                            <ul className="space-y-2 text-gray-600 list-disc list-inside">
                                <li>Delivery/Open Rates</li>
                                <li>Click-Thru Rates</li>
                                <li>Bounces/Unsubscribes</li>
                                <li>Performance Analysis</li>
                            </ul>
                        </div>
                    </div> */}
                    <div>
                    <div className="mb-4 border-l-2 border-primary pl-4">
                    <h2 className="w-full text-lg mb-8 text-black">
                            For email <strong>delivery</strong>, our services include bulk email
                            blasts, HTML or text set-up, image hosting,
                            suppression, personalization of transmission, and
                            scheduling. Regarding <strong>security</strong>, we conduct spam
                            checks and blacklist screening and are CAN-SPAM
                            compliant. And when it comes to <strong>tracking</strong> your email
                            campaign, we can provide reports for delivery/open
                            and click-thru rates, in addition to
                            bounces/unsubscribes and performance analysis.
                            </h2>
                    </div>
                    </div>
                </>
            }
            center={
                <ContactBanner title="Let us take the hassle out of email fulfillment." email={'sales@infocusmarketing.com'}>
                    {/* <div className="flex items-center flex-col mb-[50px] gap-2">
                        <h4 className="text-lg font-bold text-primary mb-2 border-b-2 border-primary w-[50%] text-center pb-3">
                            Want to learn more?
                        </h4>
                        <MicrosoftCalendarButton />
                    </div> */}
                </ContactBanner>
            }
        />
    );
}
