import React from "react";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import IssueIcon from "../../ui/Icons/IssueIcon";
import FAQsItem from "../../components/FAQsItem/FAQsItem";
import {faqs} from "../../mockData";

export default function FAQsPage() {
    return (
        <div className="containerApp">
            <TitleContainer
                Icon={IssueIcon}
                title={"Frequently Asked Questions"}
            />
            <div className="flex flex-col gap-6 py-8 md:w-1/2 mx-auto">
                {faqs.map((faq, index) => (
                    <FAQsItem key={index} faq={faq} />
                ))}
            </div>
            <div className="bg-primary min-h-[154px] rounded-t-3xl text-white flex flex-row justify-center items-center gap-4 xl:gap-10 flex-wrap xl:flex-nowrap">
                <div className="items-center min-h-[100px] pl-[10px] font-bold text-md md:text-[18px] h-full text-center p-[10px]">
                    If you have any questions or would like to speak to one of our knowledgeable and friendly staff, please call <a href="tel:(800)708-5478" className="underline">(800) 708-5478</a> and we would be happy to assist you.
                </div>
            </div>
        </div>
    );
}
