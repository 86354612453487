import React from "react";
import "./AchievingSuccess.css";

const AchievingSuccess = () => {
    const services = [
        {
            icon: "/icon1.png",
            title: "List Management",
            description: 'As the #1 list manager for associations, we optimize your list rental income while you retain complete control and security over your member and attendee databases. And there are no fees for this service - we work strictly on commission',
            link: "/services/list-management",
        },
        {
            icon: "/icon2.png",
            title: "Email Fulfillment",
            description: 'Our state-of-the-art transmission software utilized by experienced email experts ensures your email campaigns are securely and effectively deployed and tracked while adhering to all CAN-SPAM regulations',
            link: "/services/email-fulfillment",
        },
        {
            icon: "/icon3.png",
            title: "Mailing Services",
            description: 'Whether it’s a postcard, self-mailer, or personalized mail campaign, our experienced direct mail production managers ensure your mail campaign is delivered accurately and on-time for the lowest cost possible',
            link: "/services/mailing-services",
        },
        {
            icon: "/icon4.png",
            title: "List Acquisition",
            description: 'We specialize in researching and delivering the most responsive postal and email lists on the market in virtually every industry. And best of all… our list acquisition services are complimentary',
            link: "/services/list-acquisition",
        },
        {
            icon: "/icon5.png",
            title: "CE Management",
            description: 'On our dime, we increase your CE sales while eliminating your internal costs related to marketing, managing, and the LMS software for your CE program. And just like our list management service, we work strictly on commission',
            link: "/services/ce-management",
        },
        {
            icon: "/icon6.png",
            title: "Digital Products",
            description: 'We leverage your existing systems to provide innovative and cost-effective turnkey Digital Products that will enhance your online presence and optimize workflows while significantly reducing your IT and software costs',
            link: "/services/it",
        },
    ];

    return (
        <div className="achieving-success-wrapper">
            <div className="achieving-success">
                <div className="achieving-content">
                    <p className="achieving-subtitle">
                        So what can we do for you?
                    </p>
                    <div className="achieving-heading-container">
                        <h1 className="achieving-heading">
                            Achieving Success—
                            <span className="achieving-highlight">
                                Together
                            </span>
                        </h1>
                        <p className="achieving-description">
                            Since 1990, we have evolved from the leading
                            association list manager into an effective,
                            full-service, non-dues revenue service provider for
                            hundreds of professional associations.
                        </p>
                        <p className="achieving-description">
                            Whether you’re looking to outsource your list rental
                            and CE programs… need mailing or email services… or
                            want digital products to enhance your functionality and
                            online presence, INFOCUS Marketing is your trusted
                            partner with a vested interest in your success.
                            Click the icons to learn more about our services.
                        </p>
                    </div>
                </div>
                <div className="achieving-layout">
                    <div className="achieving-services">
                        {services.map((service, index) => (
                            <a
                                key={index}
                                className="service-card relative group"
                                href={service.link}
                            >
                                <div>
                                    <img
                                        className="w-full h-full"
                                        src={service.icon}
                                        alt=""
                                    />
                                </div>
                                <h3 className="service-title">
                                    {service.title}
                                </h3>
                                <p className="service-description absolute inset-0 bg-opacity-100 text-white flex items-center justify-center text-center opacity-0 transition-opacity duration-500 group-hover:opacity-100 p-4">
                                    {service.description}
                                </p>
                            </a>
                        ))}
                    </div>
                    <div className="flex space-x-4 mt-6 hidden md:block">
                        <button className="button px-6 py-3 bg-white border border-black rounded-lg shadow-lg">
                            <a
                                href="https://lists.infocusmarketing.com/lists/search"
                                target="_blank"
                                rel="noreferrer"
                                className="text-black font-bold"
                            >
                                Search Our Lists
                            </a>
                        </button>
                        <button className="button px-6 py-3 bg-white border border-black rounded-lg shadow-lg">
                            <a
                                href="https://lists.infocusmarketing.com/lists/related"
                                target="_blank"
                                rel="noreferrer"
                                className="text-black font-bold"
                            >
                                Search Related Lists
                            </a>
                        </button>
                    </div>
                    <div className="md:hidden flex flex-col gap-2 w-full">
                        <button className="button primary">
                            <a
                                href="https://lists.infocusmarketing.com/lists/search"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Search Our Lists
                            </a>
                        </button>
                        <button className="button secondary">
                            <a
                                href="https://lists.infocusmarketing.com/lists/related"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Search Related Lists
                            </a>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AchievingSuccess;
