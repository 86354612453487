import React from "react";

export default function LocationIcon() {
    return (
        <svg
            width="35px"
            height="35px"
            viewBox="0 0 35 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.9943 0C7.62607 0 4.08776e-06 7.56893 0 16.8669C-5.83965e-06 33.2273 16.0362 45.6652 16.0362 45.6652C16.31 45.882 16.6498 46 17 46C17.3502 46 17.69 45.882 17.9638 45.6652C17.9638 45.6652 34 33.2273 34 16.8669C34 7.56893 26.3625 0 16.9943 0ZM16.9943 3.06774C24.6925 3.06774 30.9091 9.22644 30.9091 16.8669C30.9091 30.2511 18.698 40.8256 17.0057 42.2578C15.3215 40.8327 3.0909 30.2563 3.09091 16.8669C3.09091 9.22644 9.29612 3.06774 16.9943 3.06774Z"
                fill="white"
            />
            <path
                d="M16.4945 9C12.3711 9 9.00001 12.3711 9 16.4945C8.99997 20.6179 12.3711 24 16.4945 24C20.6178 24 24 20.6179 24 16.4945C24 12.3711 20.6178 9 16.4945 9ZM16.4945 12C18.9969 12 21 13.992 21 16.4945C21 18.9969 18.9969 21 16.4945 21C13.992 21 12 18.9969 12 16.4945C12 13.992 13.992 12 16.4945 12Z"
                fill="white"
            />
        </svg>
    );
}
