import styles from "./Testimonials.module.css";

const Testimonials = ({testimonials}) => {
    return (
        <div className={styles.testimonials_container_wrapper}>
            <div id="carousel" className={styles.testimonials_container}>
                {testimonials.map((testimonial, index) => (
                    <div key={index} className={styles.testimonial_card}>
                        <img
                            src={testimonial.logo}
                            alt={`${testimonial.name} logo`}
                            className={styles.testimonial_logo}
                        />
                        <div className="text-center">
                            <h4 className={styles.name}>{testimonial.name}</h4>
                            <p className={styles.role}>{testimonial.role}</p>
                        </div>
                        <p className={styles.feedback}>
                            "{testimonial.feedback}"
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Testimonials;
