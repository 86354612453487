import html1 from "./content/blogs/1.html";
import html2 from "./content/blogs/2.html";
import html3 from "./content/blogs/3.html";

export const testimonials = [
    {
        logo: "oncology_nursing_society.png",
        name: "Kristina Gantner",
        role: "Marketing Specialist",
        feedback:
            "INFOCUS has made it easy to offer the rental of our list to perspective clients without the daily hassles it requires. Because their sales force works to find new buyers for our list, we’ve been able to grow our customer base while we stay free to focus on other aspects of our organization.",
    },
    {
        logo: "green-america-logo-url.png",
        name: "Shaina Shealy",
        role: "Membership Marketing Coordinator",
        feedback:
            "The staff at INFOCUS helps us keep our work on track by providing us with the most up-to-date, efficient methods of printing for our unique needs as a non-profit organization. The people I have worked with at INFOCUS are attentive and organized—crucial traits for people who handle our complex direct mail needs. I totally trust INFOCUS!",
    },
    {
        logo: "baptist-world-alliance-logo-url.png", // Replace with actual logo URL
        name: "Kathe Tranynham",
        role: "Associate Director for Promotion & Development",
        feedback:
            "Thanks to INFOCUS, we have anxiety-free print and mail projects. From concept to delivered product, every request we make is met with cooperation and a “no problem” attitude. I appreciate most that INFOCUS pays attention—that is, we aren’t rebuilding the wheel from one job to the next. Professional, on-time, and great pricing has made us quit shopping around.",
    },
    {
        logo: "Attila_Glatz.png",
        name: "Krisztina Szilassy",
        role: "Director of Operations",
        feedback:
            "We were looking for a printer to complete a rush order for one of our concerts in the Washington, D.C. area and found INFOCUS Marketing by chance. The quality, the service AND the price was so good we are now a returning customer. It is so reassuring to know we just need to hand over the files and everything will be taken care of on time, within budget. INFOCUS was capable of handling a small rush order we wanted in our first order, as well as a multi-destination custom order with huge numbers we are now trusting in their capable hands. I would recommend INFOCUS to anyone in the industry.",
    },
    {
        logo: "WashMyDeck.png",
        name: "Steve Chapman",
        role: "President and Deck Restoration Guru",
        feedback:
            "I am proud to give a whole hearted recommendation for INFOCUS Marketing, we use them for list management, printing, mailing and fulfillment. They keep our projects on time and has given us great suggestions to save money. I have used many mail houses in the past, INFOCUS stands above the rest with their professionalism and reliability.",
    },
    {
        logo: "Human_Life_International.png",
        name: "Margot LaPenta",
        role: "Development Manager",
        feedback:
            "Human Life International has successfully done small and large projects with INFOCUS for many years.  Their staff is diligent about making sure that mailings are done precisely and without error, no matter what the quantity.  They do an excellent job meeting deadlines even when quick turnaround is necessary.  With each project you will receive great attention and service from a staff willing to make the extra effort to get the job done right.",
    },
    {
        name: "Mark Hamilton",
        role: "Executive Director",
        logo: "American_Mental.png",
        feedback:
            "We are pleased with the amount of revenue that INFOCUS has generated from the rental of our membership list. As a matter of fact, they've exceeded our first year revenue goal quite significantly. We are more than satisfied with the service provided by INFOCUS and I would highly recommend them.",
    },
    {
        name: "Christy Gaynor",
        role: "Associate Channel Manager",
        logo: "Associate_Channel.png",
        feedback:
            "INFOCUS consistently provides me with targeted lists. I tell them who I am looking for and they take on the time consuming task of doing all the research for me. Working with the professional, reliable staff at INFOCUS has been very helpful. I appreciate the support that INFOCUS provides.",
    },
    {
        name: "Robin Castorina",
        role: "Marketing Assistant",
        logo: "Taylor_Francis.png",
        feedback:
            "I am very pleased with the level of customer service that I consistently receive when I work with the group at INFOCUS. The Account Executives are knowledgeable and willing to go the extra mile to ensure my mail campaign gets out the door on time. And you can't beat the responsiveness of the mailing lists - I rent lists on a regular basis and am pleased with the performance every time.",
    },
    {
        name: "Jeannie Rindfuss",
        role: "Office Manager",
        logo: "Heritage.png",
        feedback:
            "INFOCUS provided us with an extremely quick turn around and everything went very smoothly with the data conversion. We will definitely be using their services again.",
    },
    {
        name: "Bill Fletcher",
        role: "President",
        logo: "The_Right_Lists.png",
        feedback:
            "INFOCUS is the first place we look to find the most responsive association lists for our customers! Our customers consistently get excellent response rates with their high quality names.",
    },
    {
        name: "William P. Coyle",
        role: "Conference Coordinator",
        logo: "Conference_Coordinator.png",
        feedback:
            "INFOCUS lists are always accurate and up-to-date. We have reported an exceedingly low number of mail returns. Service is prompt and personal attention immediate. We have been completely satisfied with their work for us.",
    },
    {
        name: "Andrew Mills",
        role: "List Broker",
        logo: "Hippo_Direct.png",
        feedback:
            "INFOCUS provides quick and accurate information about their lists. They often process orders before other managers even confirm receipt. Thanks for helping us provide great service to our clients!",
    },
    {
        name: "Beth Bernardi",
        role: "MBA, Marketing Membership Officer, Emergency Nurses Association",
        logo: "testimonial1.png",
        feedback: "We’ve been working with INFOCUS for the last 5 years. They have always been responsive to our needs. Administratively they have been proactive and continue to assist us in making our list rental a positive experience. If I am concerned over a particular list usage, they handle the situation and make it a win for all parties concerned. They are consistently working to introduce our lists to new clients and provide us with opportunities to grow our revenue."
    },
    {
        name: "Dr. Pat Wolferth-Bethke",
        role: "Assistant Director, Membership and Field Services, American Veterinary Medical Association",
        logo: "testimonial2.png",
        feedback: "INFOCUS does a wonderful job working with our unique needs by marketing our mailing list appropriately which creates a source of revenue that can fund additional association projects."
    },
    {
        name: "Gary Bennett",
        role: "Director, Marketing and Member Services, Institute for Operations Research and the Management Sciences",
        logo: "testimonial4.jpg",
        feedback: "INFOCUS has been our list management company for quite a few years and can always be counted on to treat our mutual customers quickly and fairly."
    }
];

export const blogs = [
    {
        id: 1,
        category: "TECHNOLOGY",
        readTime: "10 MIN READ",
        title: "How Associations Can Leverage AI to Improve Customer Service",
        description:
            "Exceptional customer service is at the heart of successful associations. Members expect timely, personalized, and efficient interactions, and failing to meet these expectations can lead to dissatisfaction and disengagement. Enter Artificial Intelligence (AI) – a powerful tool that is transforming customer service across industries. ",
        content: html1,
        image: "/blog_1.jpg",
    },
    {
        id: 2,
        category: "TECHNOLOGY",
        readTime: "15 MIN READ",
        title: "Latest Trends in Association Continuing Education in 2025",
        description:
            "In 2025, the world of continuing education is evolving rapidly, driven by technological advancements and shifting member expectations. Associations play a crucial role in delivering high-quality, relevant educational opportunities to their members, ensuring they remain competitive in their respective industries. To stay ahead, associations must embrace the latest trends shaping continuing education. In this blog, we explore the key trends for 2025 and how associations can leverage them to enhance their educational offerings.",
        content: html2,
        image: "/blog_2.jpg",
    },
    {
        id: 3,
        category: "TECHNOLOGY",
        readTime: "25 MIN READ",
        title: "Association Technology to Save Costs and Increase Revenues",
        description:
            "In today’s dynamic landscape, professional associations face growing challenges in delivering value to members while maintaining operational efficiency. Leveraging technology and digital tools has become a game-changing strategy for associations, enabling them to cut costs, generate new revenue streams, and enhance member satisfaction. In this blog, we explore how associations can use technology effectively and how Infocus can support these initiatives.",
        content: html3,
        image: "/blog_3.jpg",
    },
];

export const faqs = [
    {
        question: "What is List Rental?",
        answer: "An arrangement whereby a list owner furnishes names and contact information to a mailer, together with the privilege of using the list on a one-time basis only (unless otherwise specified in advance). For this privilege, the list owner is paid a rental fee by the mailer.",
    },
    {
        question: "What is List Brokerage?",
        answer: "A complimentary consulting service that identifies the most responsive prospect list(s) for your mail campaign and then handles all the order processing details on your behalf.",
    },
    {
        question: "What is List Management?",
        answer: "A commission-based service that generates substantial revenue for your organization by promoting your member or customer database for rental (on a one-time use basis) to organizations advertising through the mail",
    },
    {
        question: "I need help finding the right list, where do I begin?",
        answer: "Simply call INFOCUS. Our knowledgeable staff will discuss the scope and nature of your mail campaign and acquire the right lists.",
    },
    {
        question: "How many times can I use a rented list?",
        answer: "Typically, all lists may be rented for a one-time use only. However, ask your INFOCUS representative about discounts for multiple uses.",
    },
    {
        question: "Why does INFOCUS have the most responsive Lists?",
        answer: "When you use an association list exclusively managed by INFOCUS, you receive the most active, influential and direct mail responsive prospects in their market. Why? Individuals join an association to receive more information about products and services related to their profession or personal interests. Associations also have high renewal rates and are more active and up-to-date than other types of mailing lists. This is why association members are regarded as the most responsive prospects for your offer.",
    },
    {
        question: "Why does my mail piece have to be approved?",
        answer: "List owners want to make sure that individuals on their lists will receive information and offers that are applicable to them, and therefore reserve the right to approve your mail piece. This preserves the responsiveness of the list.",
    },
    {
        question: "How long does the approval process take?",
        answer: "The approval process varies depending on the list owner, but typically takes anywhere from 2 – 8 business days.",
    },
    {
        question: "Can INFOCUS help me get my mailing out?",
        answer: "Yes! As we provide for hundreds of mailers like yourself, INFOCUS utilizes its vast knowledge of both letter shop techniques and postal regulations to ensure your mail campaign is taken from print to the post office with the quality of service you want and need.",
    },
    {
        question: "Can INFOCUS help me with the design of my mail piece?",
        answer: "Yes, INFOCUS offers design services.",
    },
    {
        question: "How do I order a list?",
        answer: "On the “List Management” page, click the “Search Our Lists” or “Search Related Lists” button. Use our “Search” drop down menu to find a list. Open the data card for list details. To order the list, click the “Request Estimate” link located in the “List Universe” section of the data card. Complete the “Request Estimate” form and click “Submit.” Or simply give us a call at 1.800.708.5478.",
    },
    {
        question: "What needs to be submitted for an approval?",
        answer: "A final sample of your mail piece must be submitted for the approval process. This can be faxed or emailed to your INFOCUS representative.",
    },
    {
        question: "How long will it take to get my order?",
        answer: "Depending on the list owner, turnaround times vary. Typically, it will take 5 to 10 days to process the order.",
    },
    {
        question: "Who can I contact with questions about my order?",
        answer: "If you need assistance at any time, please contact us at 800.708.5478.",
    },
];

export const listManagementData = [
    {
        title: "Sales",
        description:
            "Our track record says it all... we are the only list management company consistently able to optimize list rental income for our clients.",
    },
    {
        title: "Marketing",
        description:
            "We offer an effective, customized niche marketing strategy to promote your list to the largest database of list users in the industry.",
    },
    {
        title: "Reputation",
        description:
            "Established relationships with agencies and marketing professionals who know we manage the most responsive lists available.",
    },
    {
        title: "Quality",
        description:
            "First class service featuring an online count system, 24-hour order fulfillment and knowledgeable consultants to effectively help your customers.",
    },
    {
        title: "Data Services",
        description:
            "In-house, advanced technical facility with seasoned programmers possessing over 50 years of combined industry experience.",
    },
    {
        title: "Security",
        description:
            "List monitoring and seeding techniques coupled with regular security audits preserves the integrity of your database and gives you total control over the use of your list.",
    },
    {
        title: "Accounting",
        description:
            "Accurate and customized activity reports along with an impressive collection rate and sound fiscal infrastructure.",
    },
    {
        title: "Lead Sharing",
        description:
            "Our broad, multi-channel marketing initiatives attract lucrative leads for your other non-dues revenue and sales programs.",
    },
    {
        title: "Exposure",
        description:
            "Our ability to effectively market lists enhances the visibility of your organization within your own industry and the advertising community.",
    },
];
