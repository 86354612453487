import React from "react";

const MicrosoftCalendarButton = ({ url }) => {

  const goto = url || "https://outlook.office365.com/owa/calendar/Infocus@infocusmarketing.com/bookings/";

  const handleClick = () => {
    window.open(
      goto,
      "_blank", // Open in a new tab
      "width=800,height=600,scrollbars=yes,resizable=yes" // Optional popup window settings
    );
  };

  return (
    <button
      onClick={handleClick}
      className="px-4 py-2 bg-red-700 text-white rounded hover:bg-red-600 transition-colors duration-300"
    >
      Book a Virtual Meeting
    </button>
  );
};

export default MicrosoftCalendarButton;