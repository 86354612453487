import React from "react";
import "./TrustedSection.css";

const TrustedSection = () => {
    const logos = [
        {src: "/logo1.png", alt: "American College of Cardiology"},
        {src: "/logo2.png", alt: "ASCLS"},
        {src: "/logo3.png", alt: "ASHRAE"},
        {src: "/logo4.png", alt: "American Association for Justice"},
        {src: "/logo5.png", alt: "AMSUS"},
        {src: "/logo6.jpg", alt: "American Association of Colleges of Nursing"},
        {src: "/logo7.jpg", alt: "APCA"},
        {src: "/logo8.png", alt: "PALTMed"},
        {src: "/logo9.jpg", alt: "AAFP"},
        {src: "/logo10.gif", alt: "Neurology"},
        {src: "/logo11.jpg", alt: "Head and Neck"},
        {src: "/logo12.jpg", alt: "Museums Association"},
        {src: "/logo13.jpg", alt: "AAGP"},
        {src: "/logo14.jpg", alt: "AAFCS"},
        {src: "/logo15.png", alt: "AANP"},
        {src: "/logo16.jpg", alt: "AVMA"},
        {src: "/logo17.jpg", alt: "AUA"},
        {src: "/logo18.jpg", alt: "Aging"},
        {src: "/logo19.gif", alt: "ASPA"},
        {src: "/logo20.png", alt: "ASCB"},
        {src: "/logo21.jpg", alt: "AOA"},
        {src: "/logo22.png", alt: "AMSA"},
        {src: "/logo23.jpg", alt: "AGA"},
        {src: "/logo24.jpg", alt: "ACR"},
        {src: "/logo25.jpg", alt: "ACOG"},
        {src: "/logo26.jpg", alt: "EIA"},
        {src: "/logo27.png", alt: "INS"},
        {src: "/logo28.jpg", alt: "IMA"},
        {src: "/logo29.jpg", alt: "JUCM"},
    ];

    return (
        <div className="trusted-associations">
            <div className="trusted-title-wrapper">
                <h2 className="trusted-title">
                    Trusted by Hundreds of Prominent Associations
                </h2>
            </div>
            <div className="logo-grid">
                {logos.map((logo, index) => (
                    <img
                        height={100}
                        width={150}
                        loading="lazy"
                        key={index}
                        src={logo.src}
                        alt={logo.alt}
                        className="logo"
                        style={{
                            "--delay": `${index * 0.1}s`,
                        }} /* Adjust delay dynamically */
                    />
                ))}
                {/* Duplicate logos for infinite scrolling */}
                {logos.map((logo, index) => (
                    <img
                        height={100}
                        width={150}
                        loading="lazy" 
                        key={`duplicate-${index}`}
                        src={logo.src}
                        alt={logo.alt}
                        className="logo"
                        style={{"--delay": `${(index + logos.length) * 0.1}s`}}
                    />
                ))}
            </div>
        </div>
    );
};

export default TrustedSection;
