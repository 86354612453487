import React from "react";

export default function AboutIcon() {
    return (
        <svg
            width="45"
            height="45"
            viewBox="0 0 45 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.5 0C10.0736 0 0 10.0735 0 22.5C0 34.9264 10.0736 45 22.5 45C34.9265 45 45 34.9264 45 22.5C45 10.0735 34.9265 0 22.5 0ZM22.5 40.5C12.5748 40.5 4.5 32.4252 4.5 22.5C4.5 12.5748 12.5748 4.5 22.5 4.5C32.4253 4.5 40.5 12.5748 40.5 22.5C40.5 32.4252 32.4253 40.5 22.5 40.5ZM25.3174 13.5C25.3174 15.1313 24.1297 16.3125 22.5229 16.3125C20.851 16.3125 19.6924 15.1313 19.6924 13.4688C19.6924 11.8709 20.8824 10.6875 22.5229 10.6875C24.1297 10.6875 25.3174 11.8709 25.3174 13.5ZM20.2549 20.25H24.7549V33.75H20.2549V20.25Z"
                fill="white"
            />
        </svg>
    );
}
