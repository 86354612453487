import React, { useState } from "react";
import CheckboxWithLabel from "../../components/CheckboxWithLabel/CheckboxWithLabel";
import Polygon from "../../components/Polygon/Polygon";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import MessageIcon from "../../ui/Icons/MessageIcon";
import PhoneIcon from "../../ui/Icons/PhoneIcon";
import EmailIcon from "../../ui/Icons/EmailIcon";
import LocationIcon from "../../ui/Icons/LocationIcon";

export default function ContactPage() {
    const [formData, setFormData] = useState({
        name: "",
        company: "",
        title: "",
        email: "",
        phone: "",
        interests: [],
        message: "",
    });

    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleChange = (e, label) => {
        const { name, value, type, checked } = e.target;

        if (type === "checkbox") {
            setFormData((prev) => ({
                ...prev,
                interests: checked
                    ? [...prev.interests, label]
                    : prev.interests.filter((interest) => interest !== label),
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const prepareFormData = () => {
        const formPayload = new FormData();

        Object.entries(formData).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach((item) => formPayload.append(key, item));
            } else {
                formPayload.append(key, value);
            }
        });

        return formPayload;
    };

    const submitForm = async (formPayload) => {
        try {
            const response = await fetch("https://api.infocusmarketing.com/contact-form", {
                method: "POST",
                body: formPayload,
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || "Something went wrong!");
            }

            return response.json();
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setSuccessMessage("");
        setErrorMessage("");

        try {
            const formPayload = prepareFormData();
            await submitForm(formPayload);

            setSuccessMessage("Your message has been sent successfully!");
            setFormData({
                name: "",
                company: "",
                title: "",
                email: "",
                phone: "",
                interests: [],
                message: "",
            });
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <main className="containerApp">
            <Polygon minHeight="min-h-[100%] bg-primary">
                <TitleContainer
                    Icon={MessageIcon}
                    title={"Contact Us"}
                    description={
                        "We'd Love To Hear From You, So We Are Going To Make This Easy. Here Are All The Ways You Can Contact Us: "
                    }
                />

                <article className="px-[57px] text-white">
                    <div className=" flex justify-start gap-5 xl:gap-[15%] flex-col xl:flex-row">
                        <div className="flex justify-start flex-col gap-2">
                            <div className="flex flex-row gap-2 items-center">
                                <LocationIcon />
                                <p className="font-bold">Mailing Address</p>
                            </div>
                            <div>
                                <p>INFOCUS Marketing, Inc.</p>
                                <p>14157 Robert Paris Court, Suite B</p>
                                <p>Chantilly, VA 20151</p>
                            </div>
                        </div>

                        <div className="flex justify-start flex-col gap-2">
                            <div className="flex flex-row gap-2 items-center">
                                <PhoneIcon className={"w-[35px]"} />
                                <p className="font-bold">Phone</p>
                            </div>
                            <div>
                                <a href="tel:(800)708-5478">
                                    <p>800.708.5478</p>
                                </a>
                                <a href="tel:(540)428-3240">
                                    <p>540.428.3240</p>
                                </a>
                            </div>
                        </div>

                        <div className="flex justify-start flex-col gap-2">
                            <div className="flex flex-row gap-2 items-center">
                                <EmailIcon />
                                <p className="font-bold">Email</p>
                            </div>
                            <div>
                                <p>
                                    <a
                                        href="mailto:sales@infocusmarketing.com"
                                        className="underline"
                                    >
                                        sales@infocusmarketing.com
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <p className="text-sm mt-6 text-[#D78378]">
                        Our Office Hours Are From 9am–5:45pm EST.
                    </p>
                </article>

                <div className="pb-[100px]"></div>
            </Polygon>

            <section className="mt-10 pb-10 container mx-auto md:w-1/2">
                <h2 className="text-xl font-bold mb-4">Drop Us a Line</h2>
                {successMessage && <p className="text-green-600">{successMessage}</p>}
                {errorMessage && <p className="text-red-600">{errorMessage}</p>}
                <form className="space-y-4" onSubmit={handleSubmit}>
                    {[
                        { label: "First and Last Name", name: "name" },
                        { label: "Company Name", name: "company" },
                        { label: "Title", name: "title" },
                        { label: "Email Address", name: "email" },
                        { label: "Phone", name: "phone" },
                    ].map(({ label, name }) => (
                        <div key={name} className="flex flex-col mb-6">
                            <label className="text-[#3D4A5C] text-[14px] font-medium mb-1">
                                {label}
                            </label>
                            <input
                                className="max-w-[800px] border-2 border-gray-200 rounded-lg w-full py-2 px-4 text-gray-700 focus:outline-none focus:border-primary"
                                name={name}
                                type="text"
                                value={formData[name]}
                                onChange={handleChange}
                            />
                        </div>
                    ))}

                    <div className="flex flex-col gap-2 sm:gap-10 md:flex-row">
                        <div>Interests:</div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                            {["List Management", "Email Fulfillment", "Mailing Services", "List Acquisition", "CE Management", "Digital Products"].map((interest) => (
                                <CheckboxWithLabel
                                    key={interest}
                                    label={interest}
                                    value={interest}
                                    checked={formData.interests.includes(interest)}
                                    onChange={handleChange}
                                />
                            ))}
                        </div>
                    </div>

                    <div className="flex flex-col mb-6">
                        <label className="text-[#3D4A5C] text-[14px] font-medium mb-1">Message</label>
                        <textarea
                            className="max-w-[800px] border-2 border-gray-200 rounded-lg w-full py-2 px-4 text-gray-700 focus:outline-none focus:border-primary"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                        />
                    </div>

                    <button
                        type="submit"
                        className="bg-primary mt-4 text-white py-2 px-4 rounded-lg hover:bg-red-700"
                        disabled={loading}
                    >
                        {loading ? "Submitting..." : "Submit"}
                    </button>
                </form>
            </section>
        </main>
    );
}