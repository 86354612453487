import React from "react";

export default function OpenIcon() {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="0" y="5" width="30" height="2" fill="#fff" />
            <rect x="0" y="14" width="30" height="2" fill="#fff" />
            <rect x="0" y="23" width="30" height="2" fill="#fff" />
        </svg>
    );
}
