import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AboutPage from "./pages/AboutPage/About.page";
import Home from "./pages/HomePage/HomePage";
import TestimonialsPage from "./pages/TestimonialsPage/TestimonialsPage";
import BlogPage from "./pages/BlogPage/BlogPage";
import FAQsPage from "./pages/FAQsPage/FAQsPage";
import CEManagementPage from "./pages/CEManagementPage/CEManagementPage";
import ListManagementPage from "./pages/ListManagementPage/ListManagementPage";
import EmailFulfillmentPage from "./pages/EmailFulfillmentPage/EmailFulfillmentPage";
import ITDigitalPage from "./pages/ITDigitalPage/ITDigitalPage";
import ListAcquisitionPage from "./pages/ListAcquisitionPage/ListAcquisitionPage";
import MailingServicesPage from "./pages/MailingServicesPage/MailingServicesPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import BlogDetailPage from "./pages/BlogDetailPage/BlogDetailPage";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";

const App = () => {
    return (
        <Router>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route
                    path="/services/list-management"
                    element={<ListManagementPage />}
                />
                <Route
                    path="/services/list-acquisition"
                    element={<ListAcquisitionPage />}
                />
                <Route
                    path="/services/email-fulfillment"
                    element={<EmailFulfillmentPage />}
                />
                <Route
                    path="/services/mailing-services"
                    element={<MailingServicesPage />}
                />
                <Route
                    path="/services/ce-management"
                    element={<CEManagementPage />}
                />
                <Route path="/services/it" element={<ITDigitalPage />} />
                <Route path="/about-us" element={<AboutPage />} />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/blog/:id" element={<BlogDetailPage />} />
                <Route path="/faqs" element={<FAQsPage />} />
                <Route path="/contact-us" element={<ContactPage />} />
                <Route path="/testimonials" element={<TestimonialsPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
            <Footer />
        </Router>
    );
};

export default App;
