import React from "react";

export default function BlogIcon() {
    return (
        <svg
            width="41"
            height="41"
            viewBox="0 0 46 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M38.4192 21.1092C38.3648 22.2352 37.5271 23.6254 36.1771 24.9615V42.5685C36.1756 43.022 36.0005 43.4063 35.7045 43.7065C35.4072 44.0008 35.0214 44.1749 34.5679 44.1779H6.43045C5.97999 44.1749 5.59275 44.0008 5.2954 43.7065C4.99945 43.4063 4.82581 43.022 4.8243 42.5685V14.4315C4.82581 13.9796 4.99945 13.5937 5.2954 13.2935C5.59275 12.9992 5.97999 12.8251 6.43045 12.8221H21.3744C22.5831 11.2037 23.9919 9.54625 25.5878 8.027C25.5952 8.01797 25.6011 8.00894 25.6085 8H6.43045C4.66385 7.99847 3.04008 8.7236 1.88438 9.88264C0.722851 11.0417 -0.00150516 12.6631 2.34846e-06 14.4316V42.5686C-0.00150516 44.3371 0.722851 45.9616 1.88438 47.1176C3.03998 48.2764 4.66385 49 6.43045 49H34.5681C36.3377 49 37.9615 48.2764 39.1171 47.1174C40.2757 45.9614 41.0015 44.3369 41 42.5685V19.3557C41 19.3557 40.9882 19.3407 40.9691 19.3256C40.2374 19.9652 39.385 20.5747 38.4192 21.1092Z"
                fill="white"
            />
            <path
                d="M45.9398 0C45.9398 0 44.4524 2.2665 34.7371 5.61926C24.8305 9.03865 18.7328 22.9487 18.7328 22.9487C17.2647 25.7984 11.4618 37.6383 11.4618 37.6383C9.86331 40.6965 12.7921 42.5037 14.5106 39.3457C17.8038 33.2836 19.9297 27.7444 24.9371 27.5873C32.2422 27.3576 37.2376 20.8514 35.6658 21.1536C33.5976 22.0723 29.0378 21.2231 31.7236 20.8C38.1709 20.2727 42.1442 15.3303 40.8494 14.9949C38.5636 15.8924 36.4317 15.0403 36.0139 14.7833C47.3931 13.3721 45.9398 0 45.9398 0Z"
                fill="white"
            />
        </svg>
    );
}
