import Hero from "../../components/HeroSection";
import TrustedSection from "../../components/TrustedSection";
import AchievingSuccess from "../../components/AchievingSuccess";
import Testimonials from "../../components/Testimonials";
import EmpowerSection from "../../components/EmpowerSection";
import InsightsSection from "../../components/InsightsSection";

const Home = () => (
    <>
        {/* <Hero /> */}
        <div className="containerApp !p-0 md:!px-[10px]">
            <AchievingSuccess />
            <TrustedSection />
            <Testimonials />
            <EmpowerSection />
        </div>
    </>
);

export default Home;
