import React from "react";
import ServiceLayout from "../../components/ServiceLayout/ServiceLayout";
import ContactBanner from "../../components/ContactBanner/ContactBanner";
import MicrosoftCalendarButton from "../../components/MicrosoftCalendarButton/MicrosoftCalendarButton";

export default function ITDigitalPage() {
    return (
        <ServiceLayout
            leftPolygonContent={
                <div className="w-full px-[30px] py-[31px] !mt-5 sm:!mt-0">
                    <div className="flex gap-2 bg-primary align-center">
                        <img src="/icon6.png" alt="icon" />
                        <h1 className="font-bold text-[32px] my-auto">
                            Digital Products
                        </h1>
                    </div>
                    <h2 className="font-normal text-[15px] mt-2">
                        Enhance your online presence and technological efficiency while cutting costs.
                    </h2>

                    <div className="pl-[10px] border-l border-white mt-[29px] mb-[70px]">
                        <p className="text-[16px]">
                            Through our <strong>exclusive technology vendor <a className="italic underline" href="https://morre.org" rel="noreferrer" target="_blank">Morre LLC</a></strong>,  your organization will benefit from a number of IT products and services that will optimize workflows and keep your organization looking great on both mobile and desktop devices.
                        </p>
                    </div>
                </div>
            }
            rightPolygonContent={
                <>
                    <img
                        className="w-full mb-5 h-[350px] object-cover rounded-b-2xl hidden md:block"
                        src="/it.png"
                        alt={"banner"}
                    />
                    <div className="max-w-7xl mx-auto">
                        <p className="text-gray-700 mb-4 border-l-2 border-primary pl-2">
                            Choose from the tech offerings below or let <strong className="italic"><a className="italic underline" href="https://morre.org" rel="noreferrer" target="_blank">Morre LLC</a></strong> help craft a custom solution just for you! <strong className="italic"><a className="italic underline" href="https://morre.org" rel="noreferrer" target="_blank">Morre LLC</a></strong> delivers high-quality, effective, and affordable solutions for organizations of all sizes.
                        </p>

                        <div className="space-y-6">
                            {[
                                {
                                    title: "Full IT Audit",
                                    items: [
                                        "Receive a detailed report and actionable roadmap to enhance your organization’s IT infrastructure and digital presence.",
                                        "Improve operational efficiency, reduce costs, and boost both functionality and visual appeal.",
                                    ],
                                    // pdf: [ "/pdf/IT-Audit.pdf" ],
                                    pdf: []
                                },
                                {
                                    title: "AI-Powered Help Desk",
                                    items: [
                                        "24/7 AI-driven customer support for enhanced member satisfaction.",
                                        "Reduce response times to mere seconds while cutting customer service labor costs.",
                                    ],
                                    // pdf: [ "/pdf/AI-Powered-Help-Desk-1.pdf", "/pdf/AI-Powered-Help-Desk-2.pdf" ],
                                    pdf: []
                                },
                                {
                                    title: "Dedicated Tech Manager",
                                    items: [
                                        "Reduce internal IT costs while gaining access to an experienced, on-call tech expert.",
                                        "Ensure timely, reliable support whenever needed.",
                                    ],
                                    // pdf: [ "/pdf/Comprehensive-Tech-Support-1.pdf", "/pdf/Comprehensive-Tech-Support-2.pdf" ]
                                    pdf: []
                                },
                                {
                                    title: "Website CMS Management",
                                    items: [
                                        "Modern, intuitive website design that aligns with your brand and engages visitors.",
                                        "Ongoing hosting, updates, and SEO optimization to improve discoverability.",
                                        "Mobile-optimized for seamless user experience across all devices.",
                                    ],
                                    // pdf: [ "/pdf/Web-Development-1.pdf", "/pdf/Web-Development-2.pdf" ],
                                    pdf: []
                                },
                                {
                                    title: "Online Forum",
                                    items: [
                                        "Strengthen member communication and foster networking opportunities.",
                                        "Fully integrated with your website, optimized for mobile, and accessible to members with exclusive content for non-members to drive conversions.",
                                    ],
                                    pdf: []
                                },
                                // {
                                //     title: "Branded Merchandise Store",
                                //     items: [
                                //         "Offer custom, branded merchandise for members to purchase directly from your site.",
                                //         "Generate additional non-dues revenue with seamless eCommerce integration (no third-party store).",
                                //     ],
                                //     pdf: []
                                // },
                                // {
                                //     title: "Private Cloud Storage",
                                //     items: [
                                //         "Safely store and sell exclusive content like e-books or guides via a paywall, increasing non-dues revenue.",
                                //         "Secure, scalable cloud infrastructure for premium resource management.",
                                //     ],
                                //     pdf: []
                                // },
                                {
                                    title: "Mobile App (iOS/Android)",
                                    items: [
                                        "Deliver timely updates and essential information directly to members' mobile devices.",
                                        "Collect valuable member feedback with ease through in-app features.",
                                    ],
                                    pdf: []
                                },
                                // {
                                //     title: "Conference Mobile App (iOS/Android)",
                                //     items: [
                                //         "Boost attendee engagement and streamline communication during events.",
                                //         "Facilitate networking and enhance the overall event experience for all participants.",
                                //     ],
                                //     pdf: []
                                // },
                                // {
                                //     title: "Cloud Infrastructure Maintenance",
                                //     items: [
                                //         "Optimize cloud expenses and ensure proper scaling to reduce monthly bills.",
                                //         "Maintain constant uptime with proactive monitoring, notifications, and secure data backups.",
                                //     ],
                                //     pdf: []
                                // },
                                // {
                                //     title: "SaaS Platform Maintenance",
                                //     items: [
                                //         "Optimize and troubleshoot AMS, CRM, and LMS systems for data consistency and operational efficiency.",
                                //         "Streamline workflows with smooth system integrations.",
                                //     ],
                                //     pdf: []
                                // },
                            ].map((section, index) => (
                                <div key={index}>
                                    <h3 className="text-lg font-semibold text-gray-800 mb-2">
                                        {section.title}
                                    </h3>
                                    <ul className="list-disc list-inside text-gray-600">
                                        {section.items.map((item, itemIndex) => (
                                            <li key={itemIndex}>{item}</li>
                                        ))}
                                    </ul>
                                    {section.pdf.length > 0 && (
                                        <div className="space-y-4 mt-4 md:w-1/2">
                                            {section.pdf.map((pdf, pdfIndex) => (
                                                <div key={pdfIndex} className="p-2 rounded shadow-lg">
                                                    <iframe
                                                        src={`${pdf}#toolbar=0`}
                                                        title={`Preview of ${pdf}`}
                                                        className="w-full h-[700px]"

                                                    ></iframe>
                                                    <div className="text-right mt-2">
                                                        <a
                                                            href={pdf}
                                                            download
                                                            className="text-blue-600 hover:underline"
                                                        >
                                                            Download PDF
                                                        </a>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            }
            center={
                <ContactBanner title="Learn about how digital products can enhance your organization." mobileNumber="(800) 708-5478 ext. 3207" email="abrooks@infocusmarketing.com">
                    <div className="flex items-center flex-col mb-[50px] gap-2">
                        <h4 className="text-lg font-bold text-primary mb-2 border-b-2 border-primary w-[50%] text-center pb-3">
                            Want to learn more?
                        </h4>
                        <MicrosoftCalendarButton url={'https://outlook.office365.com/book/INFOCUSDigitalSolutions@infocusmarketing.com/'} />
                    </div>
                </ContactBanner>
            }
        />
    );
}
