import React from "react";

export default function FAQsItem({faq}) {
    const {question, answer} = faq;
    return (
        <div className="flex flex-col gap-3">
            <div className="text-[26px] font-extrabold text-primary">
                {question}
            </div>
            <div className="text-[18px] font-medium text-[#0F0F0F] pl-3 border-l-2 border-primary">
                { question === 'How do I order a list?' && <span>You can find the lists we manage <a href="/services/list-management" className="underline">here</a>.{' '}</span> }
                <span>{answer}</span>
                { question.includes('design') && <span>{' '}<a href="/contact-us" className="underline">Contact us</a> for details.</span> }
            </div>
        </div>
    );
}
