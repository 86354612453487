import React from "react";
import Polygon from "../Polygon/Polygon";

export default function ServiceLayout({
    leftPolygonContent,
    rightPolygonContent,
    center,
}) {
    return (
        <div className={`containerApp`}>
            <div className="flex flex-col xl:flex-row gap-10 text-white">
                <div className={`basis-1/3 w-full h-full`}>
                    <Polygon minHeight="min-h-[100px]">
                        {leftPolygonContent}
                    </Polygon>
                </div>
                <div className={`basis-2/3 w-full`}>{rightPolygonContent}</div>
            </div>
            <div>{center}</div>
        </div>
    );
}
