import React from "react";
import ServiceLayout from "../../components/ServiceLayout/ServiceLayout";
import ContactBanner from "../../components/ContactBanner/ContactBanner";
import MicrosoftCalendarButton from "../../components/MicrosoftCalendarButton/MicrosoftCalendarButton";

export default function CEManagementPage() {
    return (
        <ServiceLayout
            leftPolygonContent={
                <div className="w-full px-[30px] py-[31px] !mt-5 sm:!mt-0">
                    <div className="flex gap-2 bg-primary align-center">
                        <img src="/icon5.png" alt="icon" />
                        <h1 className="font-bold md:text-[32px] text-[26px] my-auto">CE Management</h1>
                    </div>
                    <h2 className="font-normal text-[15px]">
                        Let us lift your CE program to new heights.
                        {/* <span className="font-bold">
                            CONTINUING EDUCATION revenue
                        </span> */}
                    </h2>

                    <div className="pl-[10px] border-l border-white mt-[29px] mb-[70px]">
                        <p className="text-[16px] mb-2">
                            On our dime, we will provide you with the marketing,
                            management, and LMS platform necessary to increase
                            sales and eliminate your internal costs related to
                            your CE program… all without charging you any fees
                            since we work strictly on commission.
                        </p>
                    </div>
                </div>
            }
            rightPolygonContent={
                <>
                    <img
                        className="w-full mb-5 h-[350px] object-cover rounded-b-2xl hidden md:block"
                        src="/ce-management.png"
                        alt={"banner"}
                    />
                    <div className="text-black">
                    <div className="mb-4 border-l-2 border-primary pl-4">
                        <h2 className="w-full text-lg mb-8 text-black">
                            Similar to our list management services and based on
                            our successful track record, INFOCUS has the
                            resources, experience, and know-how to increase your
                            CE program’s net margin, provide excellent customer
                            service, boost your exposure in your
                            industry/profession, attract new members and CE
                            customers, and much more.
                        </h2>
                        </div>

                        {/* <div className="mb-8">
                            <h2 className="text-lg font-semibold text-gray-800 mb-2">
                                MARKETING
                            </h2>
                            <ul className="list-disc pl-6 space-y-2">
                                <li>Increase CE Sales</li>
                                <li>
                                    Eliminate association’s internal marketing
                                    costs and labor
                                </li>
                                <li>
                                    Expand association’s exposure with
                                    non-members
                                </li>
                                <li>
                                    We reach out to non-members and generate new
                                    members for your association
                                </li>
                                <li>
                                    Proven talent and track record to optimize
                                    response
                                </li>
                                <li>
                                    We invest our own money to market your CE
                                    courses
                                </li>
                                <li>
                                    We’re already familiar with your marketplace
                                    based on INFOCUS being your list management
                                    company for a number of years.
                                </li>
                            </ul>
                            <p className="text-sm text-[#434345] mt-4">
                                *We conduct personalized marketing campaigns
                                utilizing multiple marketing channels (mail,
                                email, texting, social media, and digital) to
                                optimize response from both customers and
                                prospects.
                            </p>
                        </div>

                        <div className="mb-8">
                            <h2 className="text-lg font-semibold text-gray-800 mb-2">
                                MANAGEMENT
                            </h2>
                            <ul className="list-disc pl-6 space-y-2">
                                <li>
                                    Eliminate association’s internal management
                                    and customer service labor costs
                                </li>
                                <li>
                                    We handle all customer service needs for
                                    your CE program in a quality fashion
                                </li>
                                <li>
                                    Maintain your customer and prospect
                                    databases for both members & non-members
                                </li>
                                <li>
                                    Communicate with your members and
                                    non-members to make sure that all customers
                                    fulfill their CE credits before their
                                    renewal date
                                </li>
                                <li>
                                    Generate CE certificates both digitally and
                                    in print
                                </li>
                                <li>
                                    We work with your association to create new
                                    course packages that satisfy the exact CE
                                    requirements for the customer’s state of
                                    licensure.
                                </li>
                                <li>
                                    We update, administer, and manage your LMS
                                    software platform.
                                </li>
                                <li>
                                    Optional: we can acquire new CE courses for
                                    the association
                                </li>
                            </ul>
                            <p className="text-sm text-[#434345] mt-4">
                                *Ask us how we managed to increase one CE
                                program sales 10x in just a year.
                            </p>
                        </div>

                        <div>
                            <h2 className="text-lg font-semibold text-gray-800 mb-2">
                                SOFTWARE
                            </h2>
                            <ul className="list-disc pl-6 space-y-2">
                                <li>
                                    We utilize our own state-of-the-art LMS
                                    platform to house your CE program
                                </li>
                                <li>
                                    Eliminate all internal costs dedicated to
                                    your current LMS platform
                                </li>
                                <li>
                                    Your CE customers will have an enhanced user
                                    experience
                                </li>
                                <li>Optimized for mobile and desktop use</li>
                            </ul>
                        </div>

                        <p className="mt-6 text-[16px] font-normal  mb-4 border-l-2 border-primary pl-4">
                            INFOCUS charges our clients no fees – we work
                            strictly on commission.
                            <br />
                            This means there’s everything to gain and nothing to
                            lose by having INFOCUS serve as your association’s
                            CE Manager.
                        </p> */}
                    </div>
                </>
            }
            center={
                <ContactBanner title="Increase your association’s CE revenue while cutting costs and saving time." mobileNumber="(800) 708-5478 ext. 3242"  email="rfarkas@infocusmarketing.com">
                    <div className="flex items-center flex-col mb-[50px] gap-2">
                        {/* <h4 className="text-lg font-bold text-primary mb-2 border-b-2 border-primary w-[50%] text-center pb-3">
                            Want to learn more?
                        </h4> */}
                        {/* <MicrosoftCalendarButton url={'https://outlook.office365.com/book/INFOCUSCEManagement@infocusmarketing.com/'} /> */}
                    </div>
                </ContactBanner>
            }
        />
    );
}
