import React from "react";
import ListIcon from "../../ui/Icons/ListIcon";
import ServiceLayout from "../../components/ServiceLayout/ServiceLayout";
import ContactBanner from "../../components/ContactBanner/ContactBanner";
import MicrosoftCalendarButton from "../../components/MicrosoftCalendarButton/MicrosoftCalendarButton";

export default function ListAcquisitionPage() {
    return (
        <ServiceLayout
            leftPolygonContent={
                <div className="w-full px-[30px] py-[31px] !mt-5 sm:!mt-0">
                    <div className="flex gap-2 bg-primary align-center">
                        <img src="/icon4.png" alt="icon" />
                        <h1 className="font-bold md:text-[32px] text-[26px] my-auto">
                            List Acquisition
                        </h1>
                    </div>
                    <h2 className="font-normal text-[15px]">
                        We know lists... better than anyone else.
                    </h2>

                    <div className="pl-[10px] border-l border-white mt-[29px] mb-[70px]">
                        <p className="text-[16px]">
                            <strong>Selecting the right prospect list can make a huge
                            impact on your bottom line.</strong> Whether you are looking
                            for a postal or email list, our list experts
                            specialize in researching and delivering the most
                            responsive and credible postal and email lists in
                            virtually every industry.
                        </p>
                    </div>
                </div>
            }
            rightPolygonContent={
                <>
                    <img
                        className="w-full mb-5 h-[350px] object-cover rounded-b-2xl hidden md:block"
                        src="/list-acquisition.png"
                        alt={"banner"}
                    />
                    <div className="mb-4 border-l-2 border-primary pl-4">
                        <h2 className="w-full text-lg mb-8 text-black">
                             We have direct access to
                            every single list available on the rental market and
                            have developed long-standing relationships with the
                            most reputable list owners and list managers across
                            the nation... all for your benefit.
                        </h2>
                        {/* <ul className="list-disc pl-6 space-y-2 font-semibold">
                            <li>
                                Superior customer service from a specialized
                                team of list experts with a proven track record
                                of success.
                            </li>
                            <li>
                                Established list research process to identify
                                hard-to-find lists for targeted list
                                segmentation.
                            </li>
                            <li>
                                Customized list recommendations geared to
                                optimize response for your mail and email
                                marketing campaigns.
                            </li>
                            <li>
                                Faster turnaround time, volume discounts, better
                                credit terms and a host of other benefits only
                                we can offer.
                            </li>
                            <li>
                                Multichannel marketing campaigns are made easy
                                and effective.
                            </li>
                        </ul> */}
                        <p className="mt-4 text-red-500 font-semibold">
                            <span className="inline-block mr-2">→</span>
                            Best of all, our list acquisition services are
                            always complimentary!
                        </p>
                    </div>
                </>
            }
            center={
                <ContactBanner title="Put our list experts to work on your behalf." email={'sales@infocusmarketing.com'}>
                    {/* <div className="flex items-center flex-col mb-[50px] gap-2">
                        <h4 className="text-lg font-bold text-primary mb-2 border-b-2 border-primary w-[50%] text-center pb-3">
                            Want to learn more?
                        </h4>
                        <MicrosoftCalendarButton />
                    </div> */}
                </ContactBanner>
            }
        />
    );
}
