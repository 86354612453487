import React from "react";

export default function QuotationRightIcon({className}) {
    return (
        <svg
            className={className}
            width="106"
            height="106"
            viewBox="0 0 106 106"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.8475 86C22.1467 86 50.9999 85.5391 50.9999 46.8528C51.0148 42.5126 49.8299 38.2512 47.5735 34.5307C45.317 30.8101 42.0753 27.7725 38.1998 25.7471C34.3243 23.7217 29.9629 22.7859 25.5885 23.0412C21.2142 23.2964 16.9939 24.733 13.3853 27.1951C9.77668 29.6572 6.91748 33.0508 5.11774 37.0081C3.318 40.9653 2.64641 45.335 3.17581 49.6434C3.7052 53.9518 5.41537 58.0344 8.12086 61.4486C10.8264 64.8627 14.4239 67.478 18.5234 69.011V82.7075C18.5234 83.5808 18.8736 84.4182 19.497 85.0357C20.1204 85.6531 20.9659 86 21.8475 86ZM9.64807 46.8528C9.64807 42.2946 11.4762 37.9231 14.7303 34.7C17.9844 31.4769 22.3979 29.6662 26.9999 29.6662C31.6018 29.6662 36.0153 31.4769 39.2694 34.7C42.5235 37.9231 44.3517 42.2946 44.3517 46.8528C44.3517 71.5461 31.4542 77.5055 25.1716 78.9542V66.6075C25.1955 65.8598 24.9616 65.1263 24.5085 64.5279C24.0553 63.9294 23.4099 63.5017 22.6785 63.315C18.9777 62.3784 15.6934 60.2563 13.3367 57.2789C10.9799 54.3015 9.68313 50.6359 9.64807 46.8528Z"
                fill="white"
            />
            <path
                d="M73.8143 86C74.1135 86 103 85.539 103 46.845C103.013 42.506 101.826 38.2463 99.5701 34.5274C97.3137 30.8084 94.0731 27.7721 90.1993 25.7474C86.3255 23.7226 81.9663 22.7866 77.594 23.0408C73.2216 23.295 69.0029 24.7297 65.3947 27.1895C61.7866 29.6492 58.9267 33.0402 57.1249 36.9951C55.3231 40.9499 54.6481 45.3178 55.173 49.6256C55.6979 53.9333 57.4027 58.0167 60.1027 61.4332C62.8027 64.8497 66.3949 67.4691 70.4902 69.0076V82.7069C70.4902 83.5803 70.8404 84.4179 71.4638 85.0355C72.0872 85.6531 72.9327 86 73.8143 86ZM61.6481 46.845C61.6481 42.286 63.4762 37.9136 66.7303 34.6899C69.9844 31.4661 74.3979 29.655 78.9999 29.655C83.6019 29.655 88.0154 31.4661 91.2695 34.6899C94.5236 37.9136 96.3517 42.286 96.3517 46.845C96.3517 71.5433 83.421 77.5038 77.1384 78.9528V66.6036C77.1623 65.8558 76.9284 65.1222 76.4753 64.5236C76.0221 63.9251 75.3767 63.4972 74.6453 63.3105C70.9507 62.3676 67.6738 60.2423 65.3235 57.2649C62.9733 54.2875 61.6813 50.6246 61.6481 46.845Z"
                fill="white"
            />
        </svg>
    );
}
