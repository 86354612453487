import React, {useState} from "react";
import "./Header.css";
import CloseIcon from "../ui/Icons/CloseIcon";
import OpenIcon from "../ui/Icons/OpenIcon";

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header>
            {/* Top Section */}
            <div className="header-top">
                <p className="md:text-[18px] text-[12px] italic">HELPING ASSOCIATIONS INCREASE NON-DUES REVENUE & DECREASE INTERNAL COSTS FOR OVER 35 YEARS</p>
            </div>

            <div className="header-main-wrapper containerApp relative">
                {/* Main Section */}
                <div className="header-main">
                    <a className="header-logo" href="/">
                        <img src="/logo.jpg" alt="InFocus Marketing Logo" />
                    </a>
                    <div className="burger rounded">
                        <div className="burger-icon" onClick={toggleMenu}>
                            {isMenuOpen ? <CloseIcon /> : <OpenIcon />}
                        </div>
                    </div>

                    <nav className={`header-nav ${isMenuOpen ? "open" : ""}`}>
                        <a href="/" className="nav-link nav-link-header">
                            Home
                        </a>
                        <div className="dropdown">
                            <a href="#" className="nav-link nav-link-header">
                                Services
                            </a>
                            <div className="dropdown-content">
                                <a href="/services/list-management">
                                    List Management
                                </a>
                                <a href="/services/list-acquisition">
                                    List Acquisition
                                </a>
                                <a href="/services/email-fulfillment">
                                    Email Fulfillment
                                </a>
                                <a href="/services/mailing-services">
                                    Mailing Services
                                </a>
                                <a href="/services/ce-management">
                                    CE Management
                                </a>
                                <a href="/services/it">Digital Products</a>
                            </div>
                        </div>
                        <a href="https://lists.infocusmarketing.com/lists/all" className="nav-link nav-link-header" target="_blank" rel="noreferrer">
                            Our Clients
                        </a>
                        <a href="/about-us" className="nav-link nav-link-header">
                            About Us
                        </a>
                        {/* <a href="/blog" className="nav-link nav-link-header">
                            Blog
                        </a>
                        <a href="/testimonials" className="nav-link nav-link-header">
                            Testimonials
                        </a> */}
                        <a href="/faqs" className="nav-link nav-link-header">
                            FAQs
                        </a>
                        <a href="/contact-us" className="nav-link nav-link-header">
                            Contact Us
                        </a>
                    </nav>
                </div>

                {/* Right Section */}
                <div className="header-right">
                    <a href="tel:(800)708-5478" className="contact-info">
                        <img
                            src="/header-phone.png"
                            className="material-icons"
                        />{" "}
                        (800) 708-5478
                    </a>
                    <a
                        href="mailto:sales@infocusmarketing.com"
                        className="contact-info"
                    >
                        <img
                            src="/header-email.png"
                            className="material-icons"
                        />{" "}
                        sales@infocusmarketing.com
                    </a>
                </div>
            </div>
        </header>
    );
};

export default Header;
