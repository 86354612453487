import React from "react";

export default function QuotationLeftIcon({className}) {
    return (
        <svg
            className={className}
            width="106"
            height="106"
            viewBox="0 0 106 106"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M84.1525 20C83.8533 20 55.0001 20.4609 55.0001 59.1472C54.9852 63.4874 56.1701 67.7488 58.4265 71.4693C60.683 75.1899 63.9247 78.2275 67.8002 80.2529C71.6757 82.2783 76.0371 83.2141 80.4115 82.9588C84.7858 82.7036 89.0061 81.267 92.6147 78.8049C96.2233 76.3428 99.0825 72.9492 100.882 68.9919C102.682 65.0347 103.354 60.665 102.824 56.3566C102.295 52.0482 100.585 47.9656 97.8791 44.5514C95.1736 41.1373 91.5761 38.522 87.4766 36.989V23.2925C87.4766 22.4192 87.1264 21.5818 86.503 20.9643C85.8796 20.3469 85.0341 20 84.1525 20ZM96.3519 59.1472C96.3519 63.7054 94.5238 68.0769 91.2697 71.3C88.0156 74.5231 83.6021 76.3338 79.0001 76.3338C74.3982 76.3338 69.9847 74.5231 66.7306 71.3C63.4765 68.0769 61.6483 63.7054 61.6483 59.1472C61.6483 34.4539 74.5458 28.4945 80.8284 27.0458V39.3925C80.8045 40.1402 81.0384 40.8737 81.4915 41.4721C81.9447 42.0706 82.5901 42.4983 83.3215 42.685C87.0223 43.6216 90.3066 45.7437 92.6633 48.7211C95.0201 51.6985 96.3169 55.3641 96.3519 59.1472Z"
                fill="white"
            />
            <path
                d="M32.1857 20C31.8865 20 3.0001 20.461 3.0001 59.155C2.98742 63.494 4.17353 67.7537 6.42992 71.4726C8.6863 75.1916 11.9269 78.2279 15.8007 80.2526C19.6745 82.2774 24.0337 83.2134 28.406 82.9592C32.7784 82.705 36.9971 81.2703 40.6053 78.8105C44.2134 76.3508 47.0733 72.9598 48.8751 69.0049C50.6769 65.0501 51.3519 60.6822 50.827 56.3744C50.3021 52.0667 48.5973 47.9833 45.8973 44.5668C43.1973 41.1503 39.6051 38.5309 35.5098 36.9924V23.2931C35.5098 22.4197 35.1596 21.5821 34.5362 20.9645C33.9128 20.3469 33.0673 20 32.1857 20ZM44.3519 59.155C44.3519 63.714 42.5238 68.0864 39.2697 71.3101C36.0156 74.5339 31.6021 76.345 27.0001 76.345C22.3981 76.345 17.9846 74.5339 14.7305 71.3101C11.4764 68.0864 9.6483 63.714 9.6483 59.155C9.6483 34.4567 22.579 28.4962 28.8616 27.0472V39.3964C28.8377 40.1442 29.0716 40.8778 29.5247 41.4764C29.9779 42.0749 30.6233 42.5028 31.3547 42.6895C35.0493 43.6324 38.3262 45.7577 40.6765 48.7351C43.0267 51.7125 44.3187 55.3754 44.3519 59.155Z"
                fill="white"
            />
        </svg>
    );
}
