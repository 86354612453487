import styles from "./Polygon.module.css";

export default function Polygon({minHeight, children, bgColor}) {
    return (
        <div className={styles.container} style={{minHeight: minHeight, backgroundColor: bgColor}}>
            <div className={styles.polygon}></div>
            {children}
        </div>
    );
}
