import React from "react";

export default function MessageIcon() {
    return (
        <svg
            width="61"
            height="61"
            viewBox="0 0 61 61"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.6789 57.9261C15.946 59.3301 12.6957 57.3458 12.6957 54.2733C12.6957 52.0053 10.857 50.1667 8.58899 50.1667H8.34783C5.94659 50.1667 4 48.2201 4 45.8188V15C4 11.6863 6.68629 9 10 9H48C51.3137 9 54 11.6863 54 15V44.1667C54 47.4804 51.3137 50.1667 48 50.1667H35.2337C34.2801 50.1667 33.3401 50.394 32.4919 50.8298L18.6789 57.9261ZM8.34783 41.4855C8.34783 43.8867 10.2944 45.8333 12.6957 45.8333H13.9901C15.6764 45.8333 17.0435 47.2004 17.0435 48.8867C17.0435 51.162 19.4427 52.638 21.4736 51.6121L31.6371 46.4779C32.476 46.0541 33.4027 45.8333 34.3425 45.8333H43.6522C46.9659 45.8333 49.6522 43.147 49.6522 39.8333V19.3333C49.6522 16.0196 46.9659 13.3333 43.6522 13.3333H14.3478C11.0341 13.3333 8.34783 16.0196 8.34783 19.3333V41.4855Z"
                fill="white"
            />
            <path
                d="M19 26H18C16.3431 26 15 27.3431 15 29V30C15 31.6569 16.3431 33 18 33H19C20.6569 33 22 31.6569 22 30V29C22 27.3431 20.6569 26 19 26Z"
                fill="white"
            />
            <path
                d="M30 26H29C27.3431 26 26 27.3431 26 29V30C26 31.6569 27.3431 33 29 33H30C31.6569 33 33 31.6569 33 30V29C33 27.3431 31.6569 26 30 26Z"
                fill="white"
            />
            <path
                d="M41 26H40C38.3431 26 37 27.3431 37 29V30C37 31.6569 38.3431 33 40 33H41C42.6569 33 44 31.6569 44 30V29C44 27.3431 42.6569 26 41 26Z"
                fill="white"
            />
        </svg>
    );
}
