import React from "react";

export default function PrivacyPolicy() {
    return (
        <div className="containerApp">
            <div class="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-4 mb-10">
                <h1 class="text-3xl font-bold mb-6 text-[32px] text-primary">Privacy Policy</h1>

                <p class="mb-4">This is an Infocus Marketing, Inc. website.</p>

                <div class="mb-6">
                    <h2 class="text-xl font-semibold">Our Contact Information</h2>
                    <p>14157 Robert Paris Court, Suite B<br />Chantilly, VA 20151</p>
                    <p>Email: <a href="mailto:sales@infocusmarketing.com" class="underline">sales@infocusmarketing.com</a></p>
                    <p>Phone: <a href="tel:8007085478" class="underline">(800) 708-5478</a></p>
                </div>

                <section class="mb-6">
                    <h2 class="text-xl font-semibold">Commitment to Privacy</h2>
                    <p>INFOCUS is fully committed to respecting your online privacy and recognizes your need for appropriate protection and management of any personally identifiable information ("Personal Information") you share with us.</p>
                </section>

                <section class="mb-6">
                    <h2 class="text-xl font-semibold">Definition of Personal Information</h2>
                    <p>Personal Information means any information that may be used to identify an individual, including, but not limited to, a first and last name, a home or other physical address, an email address, or other contact information.</p>
                </section>

                <section class="mb-6">
                    <h2 class="text-xl font-semibold">Information Collection and Use</h2>
                    <p>In most cases, you can visit INFOCUS’ Web pages without revealing any Personal Information. INFOCUS does not rent, sell, or share personal information about you with other people or nonaffiliated companies.</p>
                </section>

                <section class="mb-6">
                    <h2 class="text-xl font-semibold">Tracking Technologies</h2>
                    <p>INFOCUS Web pages utilize "cookies" and other tracking technologies to improve your experience. You can control cookies through your browser settings.</p>
                </section>

                <section class="mb-6">
                    <h2 class="text-xl font-semibold">Confidentiality and Security</h2>
                    <p>INFOCUS treats Personal Information confidentially. Employees may access Personal Information only when necessary, and we maintain safeguards to protect it, complying with applicable laws.</p>
                </section>

                <section class="mb-6">
                    <h2 class="text-xl font-semibold">Data You Share with INFOCUS</h2>
                    <p>All personal data shared via online forms is stored securely in the United States. No third parties have access unless legally required or authorized by you.</p>
                </section>

                <section class="mb-6">
                    <h2 class="text-xl font-semibold">Data Retention</h2>
                    <p>Personal data is stored as permitted by law and archived after 3 years. Deleted data may still exist in backups for legal reasons.</p>
                </section>

                <section class="mb-6">
                    <h2 class="text-xl font-semibold">Accessing or Removing Your Personal Information</h2>
                    <p>You can request to view or delete your personal information by contacting us at <a href="mailto:sales@infocusmarketing.com" class="underline">sales@infocusmarketing.com</a> or <a href="tel:8007085478" class="underline">(800) 708-5478</a>.</p>
                </section>
            </div>
        </div>
    );
}
