import React, {Component} from "react";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import AboutIcon from "../../ui/Icons/AboutIcon";
import Polygon from "../../components/Polygon/Polygon";
import QuotationLeftIcon from "../../ui/Icons/QuotationLeftIcon";
import QuotationRightIcon from "../../ui/Icons/QuotationRightIcon";

const AboutPage = () => {
    return (
        <main className="containerApp">
            <Polygon minHeight="min-h-[100%] bg-primary" bgColor="white">
                <TitleContainer Icon={AboutIcon} title={"About Us"} />

                <article className="border-white text-black flex flex-col gap-9 mt-8 md:w-1/2 md:mx-auto">
                    <QuotationRightIcon className="hidden md:block absolute top-24 right-12" />
                    <QuotationLeftIcon className="hidden md:block absolute bottom-7 left-12" />

                    <h1 className="text-[26px] font-extrabold text-primary">
                        To Our Clients, Customers and Prospects:
                    </h1>

                    <p className="text-[20px] font-extrabold text-primary">
                        All humility aside, our business can be one of your
                        greatest assets.
                    </p>

                    <p>
                        My proclamation is not empty bravado. Our unique
                        business-model creates a natural win-win relationship
                        with our clients that gives us a direct stake in their
                        non-dues revenue success. We've been successful as a
                        company only because our clients have been successful.
                        This mutual incentive coupled with our ability to forge
                        long-term relationships is the main reason we are the
                        recognized leader in our industry.
                    </p>

                    <p>
                        Our story began in May of 1990. As national associations
                        were primarily relying on member dues and grants for
                        their fiscal health, INFOCUS was instrumental in
                        introducing them to the reality that they could earn
                        significant non-dues revenue by having us create and
                        manage their member list rental program.
                    </p>

                    <p>
                        Thus our business was born. Over the years, we have
                        successfully brought in over 10,000 customers renting
                        the postal and email member lists from the associations
                        we exclusively manage. Our rapid growth as a
                        full-service company can be directly attributed to our
                        ability to act quickly and effectively in response to
                        our client’s needs. Those needs are the direct result of
                        the six major services that make up INFOCUS Marketing
                        today:
                    </p>

                    <ul className="list-disc list-inside space-y-4 ml-5">
                        <li>
                            The core of our business, our <a href="/services/list-management" style={{ textDecoration: 'underline', fontWeight: 600 }}>list management</a> services generate millions of dollars in additional
                            revenue for over 120 association clients every year.
                        </li>
                        <li>
                            Our <a href="/services/list-acquisition" style={{ textDecoration: 'underline', fontWeight: 600 }}>list acquisition</a> experts provide clients with
                            complimentary assistance to identify and order the
                            most responsive prospect lists in their
                            industry/profession.
                        </li>
                        <li>
                            Our <a href="/services/email-fulfillment" style={{ textDecoration: 'underline', fontWeight: 600 }}>email fulfillment</a> services are far superior to
                            the DIY email marketing tools available, allowing us
                            to securely deliver and track your email campaigns.
                        </li>
                        <li>
                            Our <a href="/services/mailing-services" style={{ textDecoration: 'underline', fontWeight: 600 }}>mailing services</a> provides the printing and lettershop to ensure your mail campaign is delivered accurately and on-time.
                        </li>
                        <li>
                            Our <a href="/services/ce-management" style={{ textDecoration: 'underline', fontWeight: 600 }}>CE management</a> service provides you with the
                            marketing, management, and LMS platform necessary to
                            expand your CE program’s net margin and reach.
                        </li>
                        <li>
                            Our <a href="/services/it" style={{ textDecoration: 'underline', fontWeight: 600 }}>digital products</a> service allows you to enhance your
                            online presence and internal
                            functionality/efficiency for a tremendous
                            cost-savings.
                        </li>
                    </ul>

                    <p>
                        I encourage you to continue to peruse the rest of our
                        website for more details on each of our services and the
                        numerous benefits offered by each. I also invite you to
                        reach out to our knowledgeable and experienced team of
                        professionals conducting business from our office in the
                        Washington, DC suburbs.
                    </p>

                    <p>
                        Now that you know a little more about us, we look
                        forward to hearing from you to learn more about how we
                        can satisfy your organization’s needs. I'm confident
                        that once you experience the unparalleled convenience,
                        quality, and cost-efficiency of our full-service
                        capabilities, you too will agree that our business can
                        be one of your greatest assets.
                    </p>
                    <footer className="font-bold">
                        <p>Best Regards</p>
                        <p>Robert Farkas, President</p>
                        <p>INFOCUS Marketing, Incorporated</p>
                    </footer>
                </article>

                <div className="md:pb-[170px] pb-[100px] sm:mb-22"></div>
            </Polygon>
        </main>
    );
};

export default AboutPage;
