import React from "react";
import ServiceLayout from "../../components/ServiceLayout/ServiceLayout";
import ContactBanner from "../../components/ContactBanner/ContactBanner";
import MicrosoftCalendarButton from "../../components/MicrosoftCalendarButton/MicrosoftCalendarButton";

export default function ListManagementPage() {
    return (
        <ServiceLayout
            leftPolygonContent={
                <div className="w-full px-[30px] py-[31px] !mt-5 sm:!mt-0">
                    <div className="flex gap-2 bg-primary align-center">
                        <img src="/icon1.png" alt="icon" />
                        <h1 className="font-bold md:text-[32px] text-[26px] my-auto">
                            List Management
                        </h1>
                    </div>
                    <h2 className="font-normal text-[15px]">
                        Optimizing list rental revenue for over 30 years.
                    </h2>

                    <div className="pl-[10px] border-l border-white mt-[29px] mb-[70px]">
                        <p className="text-[16px]">
                            The rapid growth and ultimate success we have
                            experienced as a full-service company can all be
                            traced back to one original service we offered from
                            the start: List Management. Our innate ability to
                            optimize list rental income while allowing our
                            clients to retain complete control over the use of
                            their database is the primary reason why{" "}
                            <strong>
                                INFOCUS is the largest list manager for
                                professional and trade associations.
                            </strong>
                        </p>
                    </div>
                </div>
            }
            rightPolygonContent={
                <>
                    <div className="relative hidden md:block">
                        <img
                            className="w-full mb-5 h-[350px] object-cover rounded-b-2xl"
                            src="/banner.jpg"
                            alt="banner"
                        />
                        <div className="absolute top-1/3 right-40 flex flex-col space-y-4">
                            <button className="button px-6 py-3 bg-white border border-black rounded-lg shadow-lg">
                                <a
                                    href="https://lists.infocusmarketing.com/lists/search"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-black font-bold"
                                >
                                    Search Our Lists
                                </a>
                            </button>
                            <button className="button px-6 py-3 bg-white border border-black rounded-lg shadow-lg">
                                <a
                                    href="https://lists.infocusmarketing.com/lists/related"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-black font-bold"
                                >
                                    Search Related Lists
                                </a>
                            </button>
                        </div>
                    </div>
                    <div className="mb-8 md:hidden flex flex-col gap-2">
                        <button className="button primary">
                            <a
                                href="https://lists.infocusmarketing.com/lists/search"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Search Our Lists
                            </a>
                        </button>
                        <button className="button secondary">
                            <a
                                href="https://lists.infocusmarketing.com/lists/related"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Search Related Lists
                            </a>
                        </button>
                    </div>
                    <div>
                        <div className="mb-4 border-l-2 border-primary pl-4">
                            <h2 className="w-full text-lg mb-8 text-black">
                                We choose to specialize in association lists
                                simply because members represent the highest
                                quality and most responsive prospects available.
                                Associations choose us to manage their list
                                because we’re the best at marketing your list…
                                we provide top quality customer service… and we
                                eliminate your internal costs related to
                                managing your list rental program thanks to
                                working strictly on commission.
                            </h2>
                        </div>

                        {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
                            {listManagementData.map((item, index) => (
                                <div
                                    key={index}
                                    className="w-full p-4 rounded-lg shadow-lg bg-white border border-gray-200"
                                >
                                    <h3 className="text-base font-bold text-red-600 mb-2">
                                        {item.title}
                                    </h3>
                                    <p className="text-sm text-gray-600">
                                        {item.description}
                                    </p>
                                </div>
                            ))}
                        </div> */}
                    </div>
                </>
            }
            center={
                <ContactBanner title="Learn more about how our list management service can benefit your association." email={'sales@infocusmarketing.com'}>
                    {/* <div className="flex items-center flex-col mb-[50px] gap-2">
                        <h4 className="text-lg font-bold text-primary mb-2 border-b-2 border-primary w-[50%] text-center pb-3">
                            Want to learn more?
                        </h4>
                        <MicrosoftCalendarButton />
                    </div> */}
                </ContactBanner>
            }
        />
    );
}
