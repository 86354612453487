import React from "react";
import ServiceLayout from "../../components/ServiceLayout/ServiceLayout";
import ContactBanner from "../../components/ContactBanner/ContactBanner";
import MicrosoftCalendarButton from "../../components/MicrosoftCalendarButton/MicrosoftCalendarButton";

export default function MailingServicesPage() {
    return (
        <ServiceLayout
            leftPolygonContent={
                <div className="w-full px-[30px] py-[31px] !mt-5 sm:!mt-0">
                    <div className="flex gap-2 bg-primary align-center">
                        <img src="/icon3.png" alt="icon" />
                        <h1 className="font-bold md:text-[32px] text-[26px] my-auto">
                            Mailing Services
                        </h1>
                    </div>
                    <h2 className="font-normal text-[15px]">
                        We get it delivered on-time, accurately, and for less.
                    </h2>

                    <div className="pl-[10px] border-l border-white mt-[29px] mb-[70px]">
                        <p className="text-[16px]">
                            When it comes to getting your mail message out the
                            door, nobody does it better than INFOCUS. From
                            self-mailers to postcards to personalized mailings,
                            our experienced direct mail experts produce hundreds
                            of mail campaigns each year. We will ensure your
                            mail campaign gets assembled accurately and
                            delivered on time - all within your budget.
                        </p>
                    </div>
                </div>
            }
            rightPolygonContent={
                <>
                    <img
                        className="w-full mb-5 h-[350px] object-cover rounded-b-2xl hidden md:block"
                        src="/mailing-services.png"
                        alt={"banner"}
                    />

                    <div className="text-gray-800">
                        {/* <h1 className="text-2xl font-bold text-primary mb-4">
                            Our professional email services include:
                        </h1> */}

                        <section className="mb-8">
                            {/* <h2 className="text-xl  mb-2">
                                <span className="font-bold text-primary">
                                    Design & Copywriting{" "}
                                </span>
                            </h2>
                            <p className="mb-4 border-l-2 border-primary pl-4 font-medium ">
                                Working with a creative team of marketing and design professionals, we can help you create materials to attract attention and enhance your brand.
                            </p> */}
                            {/* <h3 className="text-[16px] font-bold mb-1">
                                Design Portfolio
                            </h3>
                            <p className="font-medium ">
                                Check out some of our designs to get an idea of
                                what we can do for you.
                            </p>
                            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-4">
                                <img
                                    src="/mailservice-1.png"
                                    alt="Design 1"
                                    className="rounded shadow"
                                />
                                <img
                                    src="/mailservice-2.png"
                                    alt="Design 2"
                                    className="rounded shadow"
                                />
                                <img
                                    src="/mailservice-3.png"
                                    alt="Design 3"
                                    className="rounded shadow"
                                />
                                <img
                                    src="/mailservice-4.png"
                                    alt="Design 4"
                                    className="rounded shadow"
                                />
                                <img
                                    src="/mailservice-5.png"
                                    alt="Design 4"
                                    className="rounded shadow"
                                />
                            </div>
                            <h3 className="text-[18px] text-gray-300 font-medium mb-1 mt-2">
                                Attention Exhibitors!
                            </h3>
                            <p className="font-medium ">
                                Are you in need of budget friendly,
                                professionally designed event materials… like
                                yesterday? Check out Exhibitor Express,
                                specifically designed for the unique needs of
                                exhibitors.
                            </p> */}
                        </section>

                        

                        <section className="mb-8">
                            <h2 className="text-[18px]  mb-2">
                                <span className="font-bold text-primary text-[22px]">
                                    Printing{" "}
                                </span>
                            </h2>
                            <p className="mb-4 border-l-2 border-primary pl-4">
                                Whether you need traditional offset printing or personalization that only digital printing can offer, we have the experience and knowledge to help you produce marketing materials that will generate response.
                            </p>
                            {/* <div className="flex flex-row justify-between flex-wrap ">
                                <ul className="list-disc pl-5 mb-4">
                                    <li>Custom Printing Projects</li>
                                    <li>Business Card Printing</li>
                                    <li>Brochure Printing</li>
                                    <li>Variable Data Production</li>
                                    <li>Postcard Printing AND MORE</li>
                                </ul>
                                <ul className="list-disc pl-5 mb-4">
                                    <li>Variable Data Production</li>
                                    <li>Directory Printing</li>
                                    <li>Offset Printing</li>
                                    <li>
                                        Bindery, Presentation Folders/Marketing
                                        Materials
                                    </li>
                                    <li>Digital Printing</li>
                                </ul>
                            </div> */}
                        </section>

                        <section className="mb-8">
                            <h2 className="text-[18px] mb-2">
                                <span className="font-bold text-primary text-[22px]">
                                    Lettershop
                                </span>{" "}
                            </h2>
                            <div className="mb-4 border-l-2 border-primary pl-4">
                                <p>
                                    From sophisticated mail merges to match inserting and tab sealing, we effectively handle the many details required to make sure your mailing is assembled correctly. 
                                </p>
                                {/* <p className="mt-4">
                                    Our robust mail & print services include:
                                </p> */}
                            </div>

                            {/* <div className="flex flex-row justify-between flex-wrap ">
                                <ul className="list-disc pl-5 mb-4">
                                    <li>Offset Printing</li>
                                    <li>Digital Printing</li>
                                    <li>Bulk Mail</li>
                                    <li>Labels</li>
                                    <li>Inkjet Addressing</li>
                                    <li>Folding & Perforating</li>
                                    <li>Live Postage Stamps & Metering</li>
                                </ul>

                                <ul className="list-disc pl-5 mb-4">
                                    <li>
                                        Personalization/Variable Data
                                        Capabilities
                                    </li>
                                    <li>Sophisticated Mail Merges</li>
                                    <li>Inserting & Hand Assembly</li>
                                    <li>Match Inserting</li>
                                    <li>Tab Sealing</li>
                                    <li>Delivery to Post Office</li>
                                </ul>
                            </div> */}
                        </section>

                        <section className="mb-8">
                            <h2 className="text-[18px] mb-2">
                                <span className="font-bold text-primary text-[22px]">
                                    Data
                                </span>{" "}
                            </h2>
                            <div className="mb-4 border-l-2 border-primary pl-4">
                                <p>
                                    We utilize NCOA processing, CASS Certification, data standardization, and merge-purge/suppression services to produce significant savings for your postage and printing costs.
                                </p>
                                {/* <p className="mt-4">
                                    Our data service experts utilize the latest
                                    technology and over 50 years of collective
                                    industry experience to scrub your lists and
                                    remove incorrect, incomplete, improperly
                                    formatted and duplicate records. Our data
                                    hygiene and preparation services will
                                    produce significant savings for your
                                    postage, printing, mailing or email costs—a
                                    savings which typically outweighs what you
                                    spend on data service fees!
                                </p>
                                <p className="mt-4">
                                    Our robust mail & print services include:
                                </p> */}
                            </div>
                            {/* <p className="mb-4 font-bold">Data Hygiene</p>
                            <ul className="list-disc pl-5 mb-4">
                                <li>
                                    Data Standardization: Address
                                    Standardization, Zip+4/Bar
                                    coding/Correction, Delivery Point
                                    Verification (DPV)
                                </li>
                                <li>
                                    NCOA (The National Change of Address) ®
                                    Processing: Available from the USPS®. Adds
                                    the latest addresses from COA forms
                                    completed by consumers and businesses.
                                </li>
                                <li>
                                    CASS (Coding Accuracy Support System)
                                    Certification: Updates rural addresses to
                                    city-style addresses to allow
                                    deliverability.
                                </li>
                                <li>
                                    Merge-Purge & Suppression: Compares similar
                                    records and removes duplicates to save you
                                    money.
                                </li>
                            </ul>

                            <p className="mb-4 font-bold">Data Preparation</p>
                            <ul className="list-disc pl-5 mb-4">
                                <li>Postal Presort</li>
                                <li>Data Segmentation</li>
                                <li>Detailed Reporting</li>
                                <li>Decoy/seeding</li>
                            </ul> */}
                        </section>

                        

                        
                    </div>
                </>
            }
            center={
                <ContactBanner title="Your one-stop shop to deliver your direct mail campaign." email={'sales@infocusmarketing.com'}>
                    {/* <div className="flex items-center flex-col mb-[50px] gap-2">
                        <h4 className="text-lg font-bold text-primary mb-2 border-b-2 border-primary w-[50%] text-center pb-3">
                            Want to learn more?
                        </h4>
                        <MicrosoftCalendarButton />
                    </div> */}
                </ContactBanner>
            }
        />
    );
}
