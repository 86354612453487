import React from "react";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import BlogIcon from "../../ui/Icons/BlogIcon";
import {blogs} from "../../mockData";
import BlogCard from "../../components/BlogCard/BlogCard";

export default function BlogPage() {
    return (
        <div className="containerApp">
            <div>
                <TitleContainer Icon={BlogIcon} title={"Blog"} />
            </div>
            <div className="pb-11">
                <div className="flex flex-wrap justify-center mt-6 md:mt-12 gap-12 w-full">
                    {blogs.map((blog, index) => (
                        <BlogCard key={index} blog={blog} />
                    ))}
                </div>
            </div>
        </div>
    );
}
