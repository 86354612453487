import React from "react";
import { useParams } from "react-router-dom";
import { blogs } from "../../mockData";
import "./BlogDetailPage.css";
import BlogCard from "../../components/BlogCard/BlogCard";

export default function BlogDetailPage() {
  const { id } = useParams();
  const blog = blogs.find((item) => item.id === parseInt(id));
  const relatedBlogs = blogs.filter((item) => item.id !== parseInt(id)); // Exclude current blog

  if (!blog) {
    return <div>Blog not found!</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8 grid grid-cols-1 md:grid-cols-3 gap-16">
      {/* Main Blog Content */}
      <div className="md:col-span-2 rounded-xl bg-white">
        <div className="mb-4 text-sm text-gray-600">
          {blog.category} | {blog.readTime} min read
        </div>
        <h1 className="text-3xl font-bold mb-6 blog-title">{blog.title}</h1>
        <img
          src={blog.image}
          alt={blog.title}
          className="rounded-xl mb-6 w-full"
        />
        {/* <div className="text-lg text-gray-800 blog-description"> */}
            <div dangerouslySetInnerHTML={{__html: blog.content}}></div>
        {/* </div> */}
      </div>

      {/* Sidebar - Continue Reading */}
      <aside>
        <h2 className="text-xl font-semibold mb-4 continue-reading">Continue Reading</h2>
        <div className="space-y-6">
          {relatedBlogs.map((relatedBlog) => (
            <BlogCard key={relatedBlog.id} blog={relatedBlog} />
          ))}
        </div>
      </aside>
    </div>
  );
}