import React from 'react';
import './EmpowerSection.css';

const EmpowerSection = () => {
    return (
        <div className="empower-section">
            <div className="empower-text">
                <h2 className="empower-title">
                    Enhance Non-Dues Revenue with Our CE Management Service
                </h2>
                <p className="empower-subtitle">
                    Designed to Increase Sales, Cut Costs, & Save Time
                </p>

            </div>
            <a href="/services/ce-management">
                <button className="learn-more-btn">
                    Learn more
                </button>
            </a>
        </div>
    );
};

export default EmpowerSection;
