import React from "react";

export default function ContactBanner({ title, children, mobileNumber = '(800) 708-5478', email}) {
    return (
        <div className="flex flex-col w-full gap-6 mt-10 mb-10">
            <div className="w-full border-2 border-primary rounded-lg p-6 flex flex-col xl:flex-row items-center justify-center gap-5">
                <p
                    className="text-center flex items-center text-primary font-medium border-b xl:border-r
        xl:border-b-0 border-primary h-[80px] xl:pr-[60px]"
                >
                    {title}
                </p>
                <div className="mt-3 xl:mt-0 flex flex-col justify-center items-center xl:items-start gap-1">
                    <h4 className="font-bold ext-[14px] text-primary">
                        Contact Us
                    </h4>
                    <a
                        href="tel:(800)708-5478"
                        className="flex gap-2 items-center"
                    >
                        <img src="/icon-phone.svg" alt="phone" />
                        <span className="text-[14px] ml-1">{mobileNumber}</span>
                    </a>
                    <a
                        href={`mailto:${email}`}
                        className="flex gap-2 items-center"
                    >
                        <img src="/icon-email.svg" alt="email" />
                        <span className="text-[14px] ml-1">
                            {email}
                        </span>
                    </a>
                </div>
            </div>
            {children}
        </div>
    );
}
