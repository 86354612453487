import React from "react";

export default function CloseIcon() {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            xmlns="http://www.w3.org/2000/svg"
        >
            <line x1="0" y1="0" x2="30" y2="30" stroke="#fff" strokeWidth="4" />
            <line x1="30" y1="0" x2="0" y2="30" stroke="#fff" strokeWidth="4" />
        </svg>
    );
}
