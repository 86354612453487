import React from "react";
import "./Footer.css";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content containerApp">
                <div className="company-info">
                    <div className="company-logo-wrapper">
                        <img
                            src="/logo-footer.png" // Replace with actual logo URL
                            alt="INFOCUS Marketing Logo"
                            className="company-logo"
                        />
                    </div>
                    <div className="company-info-text">
                        <p>INFOCUS Marketing, Inc.</p>
                        <p>14157 Robert Paris Court, Suite B</p>
                        <p>Chantilly, VA 20151</p>
                        <p>Phone: 800.708.5478</p>
                        <p>Fax: 866.708.5478</p>
                        <p>© {new Date().getFullYear()}</p>
                        <a href="/privacy-policy">Privacy Policy</a>
                        {/*  |{" "} <a href="/site-map">Site Map</a> */}
                    </div>
                </div>

                {/* <div className="footer-vertical-line"></div> */}

                <div className="footer-nav">
                    <a href="https://lists.infocusmarketing.com/lists/all" target="_blank" rel="noreferrer">Our Clients</a>
                    <a href="https://lists.infocusmarketing.com/lists/search" target="_blank" rel="noreferrer">Our Lists</a>
                    <a href="https://lists.infocusmarketing.com/lists/related" target="_blank" rel="noreferrer">Related Lists</a>
                </div>

                {/* Services Links */}
                <div className="footer-services">
                    <h4>Services</h4>
                    <a href="/services/list-management">List Management</a>
                    <a href="/services/list-acquisition">List Acquisition</a>
                    <a href="/services/email-fulfillment">Email Fulfillment</a>
                    <a href="/services/mailing-services">Mailing Services</a>
                    <a href="/services/ce-management">CE Management</a>
                    <a href="/services/it">Digital Products</a>
                </div>

                {/* Navigation Links */}
                <nav className="footer-nav">
                    <a className="nav-link" href="/about-us">
                        About Us
                    </a>
                    <a className="nav-link" href="/blog">
                        Blog
                    </a>
                    <a className="nav-link" href="/testimonials">
                        Testimonials
                    </a>
                    <a className="nav-link" href="/faqs">
                        FAQs
                    </a>
                    <a className="nav-link" href="/contact-us">
                        Contact Us
                    </a>
                </nav>
                
            </div>

            {/* Bottom Bar */}
            <div className="footer-bottom">
                <div className="newsletter">
                    <a href="https://lists.infocusmarketing.com/subscribe" target="_blank" rel="noreferrer">Subscribe to our newsletters</a>
                </div>
                <div className="footer-vertical-line"></div>
                <div className="contact-info-container">
                    <a href="tel:(800)708-5478" className="contact-info">
                        <img
                            src="/footer-phone.png"
                            className="material-icons"
                            alt="Phone"
                        />
                        <span>(800) 708-5478</span>
                    </a>
                    <a
                        href="mailto:sales@infocusmarketing.com"
                        className="contact-info"
                    >
                        <img
                            src="/footer-email.png"
                            className="material-icons"
                            alt="Email"
                        />
                        <span>sales@infocusmarketing.com</span>
                    </a>
                </div>
            </div>

            <div className="container-logo-mobile">
                <div className="company-logo-wrapper-mobile">
                    <img
                        src="/logo-footer.png"
                        alt="INFOCUS Marketing Logo"
                        className="company-logo-mobile"
                    />
                </div>
                <div className="company-logo-wrapper-mobile">
                    <img
                        src="/asae-logo.png"
                        alt="Asae Member Logo"
                        className="company-logo-mobile"
                    />
                </div>
            </div>

            <div className="company-info-text-mobile">
                <p>INFOCUS Marketing, Inc.</p>
                <p>14157 Robert Paris Court, Suite B</p>
                <p>Chantilly, VA 20151</p>
                <p>Phone: 800.708.5478</p>
                <p>Fax: 866.708.5478</p>
                <p>© {new Date().getFullYear()}</p>
                <a href="/privacy-policy">Privacy Policy</a>
            </div>

            <div className="developer-attribution">
                <a href="https://morre.org" target="_blank" rel="noreferrer">
                    <img src="/morre.png" alt="morre.org" />
                </a>
            </div>
        </footer>
    );
};

export default Footer;
