import React from "react";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import ChatIcon from "../../ui/Icons/ChatIcon";
import Testimonials from "../../components/Testimonials/Testimonials";
import {testimonials} from "../../mockData";

export default function TestimonialsPage() {
    return (
        <div className="containerApp">
            <TitleContainer Icon={ChatIcon} title={"Testimonials"} />
            <div className="py-11">
                <Testimonials testimonials={testimonials} />
            </div>
        </div>
    );
}
