import React, { useRef, useEffect } from "react";
import "./Testimonials.css";
import { testimonials } from "../mockData";

const Testimonials = () => {
    const carouselRef = useRef(null);

    useEffect(() => {
        if (!carouselRef.current) {
            console.error("Carousel ref is not attached to any DOM element.");
        }
    }, []);

    const scrollCarousel = (direction) => {
        if (!carouselRef.current) return; // Safeguard to prevent accessing null
        const widthToScroll = 3 * 460; // Adjust this based on your card width
        if (direction === "left") {
            carouselRef.current.scrollLeft -= widthToScroll;
        } else if (direction === "right") {
            carouselRef.current.scrollLeft += widthToScroll;
        }
        
    };

    return (
        <div className="testimonials">
            <div className="testimonials-up-wrapper">
                <div className="testimonials-up">
                    <h2 className="testimonials-title">Testimonials</h2>
                    <h3 className="testimonials-subtitle">What Our Clients Say</h3>
                </div>
            </div>
            <div className="testimonials-container-wrapper">
                <button
                    className="arrow left-arrow"
                    onClick={() => scrollCarousel("left")}
                >
                    {'<'}
                </button>
                <div
                    id="carousel"
                    className="testimonials-container"
                    ref={carouselRef}
                >
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="testimonial-card">
                            <img
                                src={testimonial.logo}
                                alt={`${testimonial.name} logo`}
                                className="testimonial-logo"
                            />
                            <div className="text-center">
                                <h4 className="name">{testimonial.name}</h4>
                                <p className="role">{testimonial.role}</p>
                            </div>
                            <p className="feedback">"{testimonial.feedback}"</p>
                        </div>
                    ))}
                </div>
                <button
                    className="arrow right-arrow"
                    onClick={() => scrollCarousel("right")}
                >
                    {'>'}
                </button>
            </div>
        </div>
    );
};

export default Testimonials;